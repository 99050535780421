import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  Injector,
  signal,
  untracked,
  type OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, type Params } from '@angular/router';
import { select, Store } from '@ngxs/store';
import { debounceTime, distinctUntilKeyChanged } from 'rxjs';

import { sortDirectionToPagingOrder } from '@clover/core/helpers/paging';
import { productWorklistToRouteParam, routeParamToProductWorklist } from '@clover/products-v2/products-list.guard';
import { DeleteProduct, DeleteProductsFromWorklist, LoadProducts } from '@clover/products-v2/state/products.actions';
import { ProductsSelectors } from '@clover/products-v2/state/products.selectors';
import type { ProductsSortingProperty } from '@clover/products-v2/state/products.service';
import { TaskAssignmentType } from '@clover/tasks-v2/task-assignment/task-assignment.model';
import { TaskAssignmentService } from '@clover/tasks-v2/task-assignment/task-assignment.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { CheckboxComponent } from '@design/forms/checkbox/checkbox.component';
import type { SelectItem } from '@design/forms/select/select-item/select-item';
import { SelectItemComponent } from '@design/forms/select/select-item/select-item.component';
import { SelectComponent } from '@design/forms/select/select.component';
import { presentConfirmationDialog } from '@design/overlays/confirmation-dialog/confirm';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import type { SortDirection } from '@design/table/table';
import { TableComponent } from '@design/table/table.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';

import { ProductTableRowComponent } from './product-table-row/product-table-row.component';

@Component({
  selector: 'cc-products-table',
  imports: [
    TableComponent,
    TrComponent,
    ThComponent,
    SelectComponent,
    SelectItemComponent,
    RouterLink,
    ReactiveFormsModule,
    CheckboxComponent,
    ButtonComponent,
    ProductTableRowComponent,
    TooltipDirective,
  ],
  templateUrl: './products-table.component.html',
  styleUrl: './products-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsTableComponent implements OnInit {
  products$ = select(ProductsSelectors.products);
  worklists$ = select(ProductsSelectors.worklists);
  productsCount$ = select(ProductsSelectors.productsCount);
  loadingStatus$ = select(ProductsSelectors.productsLoadingStatus);
  sortingOptions$ = select(ProductsSelectors.productsSortingOptions);

  activeWorklist$ = select(ProductsSelectors.productsWorklist);

  checkedProductIds$ = signal<number[]>([]);

  protected readonly nameSortingOrder$ = computed(() => {
    const sortingOptions = this.sortingOptions$();
    return sortingOptions.orderBy === 'name' ? sortingOptions.order : null;
  });

  protected readonly accessSortingOrder$ = computed(() => {
    const sortingOptions = this.sortingOptions$();
    return sortingOptions.orderBy === 'accessType' ? sortingOptions.order : null;
  });

  protected readonly vendorSortingOrder$ = computed(() => {
    const sortingOptions = this.sortingOptions$();
    return sortingOptions.orderBy === 'vendor' ? sortingOptions.order : null;
  });

  protected readonly itemNumberSortingOrder$ = computed(() => {
    const sortingOptions = this.sortingOptions$();
    return sortingOptions.orderBy === 'internalItemNumber' ? sortingOptions.order : null;
  });

  protected readonly hasLoadedProducts$ = computed(() => this.products$().length > 0);
  protected readonly areAllProductsChecked$ = computed(
    () => this.checkedProductIds$().length === this.products$().length && this.hasLoadedProducts$(),
  );
  protected readonly isAllProductsCheckboxIndeterminate$ = computed(
    () => this.checkedProductIds$().length > 0 && this.checkedProductIds$().length < this.products$().length,
  );

  protected readonly queryFormControl = new FormControl<string>('');
  protected readonly worklistSelectFormControl = new FormControl<SelectItem<number | null>>(undefined);

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;

  protected readonly worklistSelectItems = computed(() => {
    const worklists = this.worklists$();

    return [
      {
        id: 'all',
        title: 'All products',
        payload: null,
      },
      ...worklists.map((worklist) => ({
        id: worklist.id.toString(),
        title: worklist.name,
        payload: worklist.id,
      })),
    ];
  });

  private readonly taskAssignmentService = inject(TaskAssignmentService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly injector = inject(Injector);
  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    effect(() => {
      const productIds = computed(() => this.products$().map((company) => company.id))();

      untracked(() => {
        this.checkedProductIds$.update((ids) => ids.filter((id) => productIds.includes(id)));
      });
    });

    effect(() => {
      const activeWorklist = this.activeWorklist$();
      this.setRouteParams({ worklist: productWorklistToRouteParam(activeWorklist?.id) });
    });
  }

  ngOnInit(): void {
    this.initQueryFilter();
    this.initWorklistFilter();
  }

  changeSort(property: ProductsSortingProperty, direction: SortDirection): void {
    this.store.dispatch(
      new LoadProducts({
        sortingOptions: {
          order: sortDirectionToPagingOrder(direction),
          orderBy: property,
        },
      }),
    );
  }

  toggleAllProducts(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.checkedProductIds$.set(this.products$().map((product) => product.id));
      return;
    }

    this.checkedProductIds$.set([]);
  }

  toggleProduct(event: Event, id: number): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.checkedProductIds$.update((ids) => [...ids, id]);
      return;
    }

    this.checkedProductIds$.update((ids) => ids.filter((productId) => productId !== id));
  }

  async removeProductsFromWorklist(): Promise<void> {
    const activeWorklist = this.activeWorklist$();
    const selectedProductIds = this.checkedProductIds$();

    const confirmed =
      (await presentConfirmationDialog(this.injector, {
        title: `Are you sure you want to delete selected products from "${activeWorklist.name}" worklist?`,
        description: `This action will remove ${selectedProductIds.length} ${
          selectedProductIds.length === 1 ? 'product' : 'products'
        } from the worklist. The products will still be available in the system.`,
        confirmActionText: 'Delete products',
        cancelActionText: 'Cancel',
        destructive: true,
        style: 'default',
      })) === 'confirm';

    if (!confirmed) return;

    this.store
      .dispatch(new DeleteProductsFromWorklist(activeWorklist.id, selectedProductIds))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.checkedProductIds$.set([]);
      });
  }

  assignTask(productId: number): void {
    this.taskAssignmentService.startTaskAssignmentFlow({
      taskType: TaskAssignmentType.Product,
      productId,
    });
  }

  deleteProduct(productId: number): void {
    this.store.dispatch(new DeleteProduct(productId));
  }

  private initQueryFilter(): void {
    this.queryFormControl.setValue(this.activatedRoute.snapshot.queryParams['query'] || '', { emitEvent: false });

    this.queryFormControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300))
      .subscribe((query) => {
        this.setRouteParams({ query: query || undefined });
      });
  }

  private initWorklistFilter(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilKeyChanged('worklist'))
      .subscribe(({ worklist: worklistParam }) => {
        const worklistId = routeParamToProductWorklist(worklistParam);
        const selectItem = this.worklistSelectItems().find((item) => item.payload === worklistId);

        this.worklistSelectFormControl.setValue(selectItem, { emitEvent: false });
      });

    this.worklistSelectFormControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((selectItem) => {
      const worklist = selectItem.payload;
      const worklistParam = productWorklistToRouteParam(worklist);

      this.setRouteParams({ worklist: worklistParam });
    });
  }

  private setRouteParams(params: Params): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
